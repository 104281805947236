import {
  Banner,
  Box,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  Layout,
  LinkList,
  NavigationTiles,
  ReadMoreLink,
  RelatedPagesContent,
  Row,
  Section,
  ServiceCards,
  ServiceCardsDefaults,
  Theme,
  Variables,
  routesObject
} from "@life-without-barriers/react-components"

import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { corporateThemeFull, youthFull, agedCareThemeFull, disabilityTheme } =
  Theme
const { DiversityAndInclusion, OurAIEP, WePutChildrenFirst } =
  RelatedPagesContent
const { youthPinkMedium } = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
  }
}

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata }
  }
}: Props) => {
  const { agedCareDefaults, disabilityDefaults, fosterCareDefaults } =
    ServiceCardsDefaults

  const fosterCare = {
    ...fosterCareDefaults,
    hoverColor: youthPinkMedium,
    mobileChevronColor: youthPinkMedium
  }

  const disability = {
    ...disabilityDefaults,
    hoverColor: disabilityTheme.light,
    mobileChevronColor: disabilityTheme.light
  }

  const agedCare = {
    ...agedCareDefaults,
    hoverColor: agedCareThemeFull.light,
    mobileChevronColor: agedCareThemeFull.light
  }

  return (
    <div>
      <Helmet
        title={`Services | ${siteMetadata.title}`}
        description={`We provide foster care, disability, aged care and other services and support for many Australian communities. We help people live their best life.`}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={corporateThemeFull.light}
      />
      <Layout theme={corporateThemeFull}>
        <Banner
          title="Our services"
          subTitle="Delivering great services that exceed people’s expectations"
          breadcrumbs={[routesObject.home]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>What do we do?</Heading>
              <p>
                The communities in which we offer services, are the same
                communities in which our staff live and belong to. This
                connection to community gives us depth of understanding and
                connection to each other. We recognise our experience as members
                of the communities and as a quality service provider offering
                services for many people who may face barriers now or in the
                future.
              </p>
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Row className="justify-center">
              <Box>
                <ServiceCards services={[fosterCare, disability, agedCare]} />
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box>
              <Heading size={2} sectionHeading className="mb3 tc-ns">
                We also offer support to
              </Heading>
              <LinkList
                links={[
                  {
                    label: "Child, youth and families",
                    to: "/services/child-youth-and-family/",
                    rooted: true,
                    color: youthFull.medium
                  },
                  {
                    label: "Mental health",
                    to: "/services/mental-health/",
                    color: corporateThemeFull.medium
                  },
                  {
                    label: "Refugees and asylum seekers",
                    to: "/services/refugees-and-asylum-seekers/",
                    color: corporateThemeFull.medium
                  },
                  {
                    label: "Housing and homelessness",
                    to: "/services/homelessness-support-services/",
                    color: corporateThemeFull.medium
                  },
                  {
                    label: "Alcohol and other drugs",
                    to: "/services/alcohol-and-other-drug-counselling/",
                    color: corporateThemeFull.medium
                  }
                ]}
              />
            </Box>
          </Row>
          <Row className="layout-readable" topMargin="standard">
            <Box>
              <Heading size={2}>Our promise to you</Heading>
              <p>
                We know you are the experts in your own life. We will listen and
                be guided by you so you are empowered to make choices, you want
                to make to improve your wellbeing and increase your opportunity
                to participate in the community. To achieve this, we will:
              </p>
              <ul>
                <li className="mb2">
                  Take the time to truly know you and your support network
                </li>
                <li className="mb2">Always act with integrity</li>
                <li className="mb2">Honour our commitments</li>
                <li className="mb2">
                  Live our values in our relationship with you
                </li>
              </ul>
              <Heading size={3}>What is different about us?</Heading>
              <p>
                Its our purpose and values. Our values are in the hearts and
                minds of our people. It’s the heartbeat of our culture.
              </p>
              <ReadMoreLink
                className="mt3 mt4-ns"
                href="/about-us/our-values/"
                rooted
                title="Read about Our Values"
                text="Read about Our Values"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Want to know more?</Heading>
                <p>
                  If you would like to know more or have any questions about any
                  of our services, then please feel free to contact us on:
                </p>
                <ContactUsPhoneLink
                  marginClasses="w-100 w-50-l mv4"
                  phoneNumber="1800935483"
                />
                <ContactUsEmailLink
                  marginClasses="w-100 w-50-l mt4"
                  text="info@lwb.org.au"
                  link="mailto:info@lwb.org.au"
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <NavigationTiles
              heading={
                <h2 className="color-lwb-theme-medium ts-display-3 tc fw8">
                  Our social policy focus
                </h2>
              }
              currentPathname={location.pathname}
              pages={[DiversityAndInclusion, OurAIEP, WePutChildrenFirst]}
            />
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default IndexPage
